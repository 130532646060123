<main class="wallpaper-lab" id="auth">
    <section class="d-flex justify-content-center align-items-center" id="login-wrap">
      <div class="p-3">
        <div class="card shadow-sm bg-white rounded p-3 login-box-width">
          <div class="card-body text-center">
            <h1>HTTP 404</h1>
            <p>Puslapis nebuvo rastas</p>
            <div class="pt-2"></div>
            <div class="dropdown-divider"></div>
            <div class="text-center pt-2">
              <a href="/">Į pagrindinį puslapį</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>