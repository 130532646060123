import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { AlertTypes } from '../enums/be-msg-enum';


@Component({
  selector: 'app-backend-error-snack-bar',
  templateUrl: './backend-error-snack-bar.component.html',
  styleUrls: ['./backend-error-snack-bar.component.scss']
})
export class BackendErrorSnackBarComponent implements OnInit {

  icon = 'danger';

  constructor(
    private snackRef: MatSnackBarRef<BackendErrorSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarModalData,
  ) { }

  ngOnInit(): void{
    if (this.data.errorHashId) {
      this.data.errorHashId = `<strong>Klaidos kodas:</strong>   ${this.data.errorHashId}`;
    }

    if (this.data.type === AlertTypes.Success) {
      this.icon = '<i class="far fa-check-circle"></i>'; // check_circle_outline // check_circle_outline // done_outline
    } else if (this.data.type  === AlertTypes.Warning) {
      this.icon = '<i class="fas fa-exclamation-triangle"></i>'; // error_outline
    } else if (this.data.type  === AlertTypes.Info) {
      this.icon = '<i class="fas fa-info-circle"></i>'; // error_outline
    } else {
      this.icon = '<i class="fas fa-exclamation-circle"></i>'; // error_outline <i class=""></i>
    }
  }

  close(): void{
    this.snackRef.dismiss();
  }
}

export interface SnackBarModalData{
  message: string;
  renderAsHtml: boolean;
  errorHashId: string;
  type: AlertTypes;
  // { message, renderAsHtml, errorHashId, type }
}
