import {Component} from "@angular/core";
import {NotificationService} from "./services/notification.service";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.less']
})
export class NotificationsComponent {
  constructor(public ns: NotificationService) {
  }


}
