<div>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        <app-count-down
          (onTimerFinished)="endSession()"
          [timerMinutes]="timerTimeInMinutes"
        ></app-count-down>
      </h5>
    </div>
    <div class="modal-body">
          <h5>Baigiasi sesija.</h5>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="answerNo()"
      >
        Baigti
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="answerYes()"
        #btnYes
      >
        Tęsti
      </button>
    </div>
  </div>
</div>
