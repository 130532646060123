import {NgModule} from "@angular/core";
import {NotificationsComponent} from "./notifications.component";
import {CommonModule} from "@angular/common";
import {NotificationComponent} from "./components/notification/notification.component";

@NgModule({
  declarations: [NotificationsComponent, NotificationComponent],
  imports: [
    CommonModule
  ],
  exports: [NotificationsComponent],
})
export class NotificationsModule {}
