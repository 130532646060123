import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessDeniedComponent } from './auth/access-denied/access-denied.component';
import { AuthComponent } from './auth/auth/auth.component';
import { HttpNotFound404Component } from './auth/http-not-found404/http-not-found404.component';
import { SubjectComponent } from './modules/subject/subject.component';
import { requireAnyRole } from './auth/auth/auth.guard';
import { userPermissions } from './modules/user/models/user-permissions';

const routes: Routes = [
  {
    path: '', component: SubjectComponent,
    canActivate: [requireAnyRole(userPermissions.VIEW_SUBJECT_LIST)]
  },
  { path: 'login', component: AuthComponent },
  {
    path: 'subject',
    loadChildren: () =>
      import('./modules/subject/subject.module').then(
        (m) => m.SubjectModule
      ),
  },
  {
    path: 'control-objects',
    loadChildren: () =>
      import('./modules/control-object/control-object.module').then(
        (m) => m.ControlObjectModule
      ),
  },
  {
    path: 'plans',
    loadChildren: () =>
      import('./modules/plan/plan.module').then((m) => m.PlanModule),
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./modules/order/order.module').then((m) => m.OrderModule),
  },
  {
    path: 'inspections',
    loadChildren: () =>
      import('./modules/inspection/inspection.module').then(
        (m) => m.InspectionModule
      ),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./modules/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: '**', component: HttpNotFound404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
