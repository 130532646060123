<main class="wallpaper-lab" id="auth">
  <section
    class="d-flex justify-content-center align-items-center"
    id="loginWrap"
  >
    <div class="p-3">
      <div class="card shadow-sm bg-white rounded p-3 login-box-width">
        <div class="text-center">
          <div class="logo-img pb-3">
            <img
              src="./../../../assets/images/common/coat_h60.png"
              alt="herbas"
              height="60"
            />
          </div>
          <div class="logo-text pb-3 pr-3 pl-3" style="max-width: 400px">
            LIETUVOS RESPUBLIKOS VALSTYBINĖ MAISTO IR VETERINARIJOS TARNYBA
          </div>
        </div>
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
      <!-- /card-body -->
    </div>
  </section>
</main>
