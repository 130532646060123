import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalSettings } from 'src/environments/global-settings';
import { HistoryService } from './services/history.service';
import { IdleService } from './services/idle.helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {

  constructor(
    readonly historyService: HistoryService,
    private idleService: IdleService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.initialIdleSettings();
  }

  private initialIdleSettings() {
    const idleTimeoutInSeconds: number = GlobalSettings.SessionTimeTillTimer * 60;
    this.idleService
      .startWatching(idleTimeoutInSeconds)
      .subscribe((isTimeOut: boolean) => {
        if (isTimeOut) {
          this.idleService.openDialog(this.dialog);
        }
      });
  }
}
