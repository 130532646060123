import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { BackendErrorHandlerService, EMessageTypes } from 'src/app/services/backend-error-handler.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from "@angular/router";
import { EMPTY, catchError, throwError } from 'rxjs';
import { EHttpStatusCode } from 'src/app/shared/enums/http-codes-enum';


export const ErrorInterceptor: HttpInterceptorFn = (request, next) => {
  const authService = inject(AuthService);
  const errorHandler = inject(BackendErrorHandlerService);
  const router = inject(Router);

  return next(request).pipe(catchError(err => {
    let error;
    if (errorHandler.isValidationError(err?.error)) {
      error = err.error;
    } else {
      error = ((typeof err.error === 'string') ? err.error : null) || err.error?.message || err.message || err.statusText;
    }
    //err instanceof HttpErrorResponse
    switch (err.status) {
      case EHttpStatusCode.Unauthorized :
        authService.loginRedirect();
        return EMPTY;
      case EHttpStatusCode.Forbidden:
        const err = {success: true, messageType: EMessageTypes.Danger, message: "Prieiga negalima."};
        errorHandler.renderHandledError(err);
        router.navigate(['/access-denied']);
        return EMPTY;
      case EHttpStatusCode.BadRequest:
        if (errorHandler.isValidationError(error)) {
          errorHandler.renderHandledError(error);
        } else {
          const badRequestErr = {success: true, messageType: EMessageTypes.Danger, message: `Įvyko klaida. Klaida: ${error}`};
          errorHandler.renderHandledError(badRequestErr);
        }
        return EMPTY;
      case EHttpStatusCode.UnprocessableEntity:
        const unprocessableEntityErr = {success: true, messageType: EMessageTypes.Danger, message: `Įvyko klaida. Klaida: ${error}`};
        errorHandler.renderHandledError(unprocessableEntityErr);
        return EMPTY;
      default :
        break;
    }
    return throwError(() => error);
  }));
};