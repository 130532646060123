import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AuthInterceptor } from './auth/interceptors/auth.interceptor';
import { ErrorInterceptor } from './auth/interceptors/error.interceptor';
import { LayoutModule } from './layout/layout.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NotificationsModule } from './modules/notifications/notifications.module';
import { HistoryService } from './services/history.service';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthComponent } from './auth/auth/auth.component';
import { AccessDeniedComponent } from './auth/access-denied/access-denied.component';
import { HttpNotFound404Component } from './auth/http-not-found404/http-not-found404.component';
import { BackendErrorSnackBarComponent } from './shared/backend-error-shack-bar/backend-error-snack-bar.component';
import { CountDownComponent } from './auth/count-down/count-down.component';
import { IdleSessionBoxComponent } from './auth/idle-session-box/idle-session-box.component';
import { MatDialogModule } from '@angular/material/dialog';
import { registerLocaleData } from '@angular/common';
import localeLt from '@angular/common/locales/lt';
registerLocaleData(localeLt, 'lt');

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    AccessDeniedComponent,
    HttpNotFound404Component,
    BackendErrorSnackBarComponent,
    CountDownComponent,
    IdleSessionBoxComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    LayoutModule,
    FontAwesomeModule,
    NotificationsModule,
    NoopAnimationsModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
  ],
  exports: [],
  providers: [
    HistoryService,
    { 
      provide: LOCALE_ID, 
      useValue: 'lt' 
    },
    provideHttpClient(withInterceptors([AuthInterceptor, ErrorInterceptor])),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
