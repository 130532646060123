import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackendErrorHandlerService } from 'src/app/services/backend-error-handler.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private errorHandler: BackendErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.checkRouteChanges();
  }

  private checkRouteChanges(): void {
    this.authService.getLogin().subscribe({
      complete: () => {
        this.router.navigate([this.authService.getHomeRoute()]);
      },
      error: (e) => {
        this.errorHandler.renderHandledError(e);
      }
    });
  }
}