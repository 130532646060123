import {Component, ViewChild, ElementRef} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalSettings } from 'src/environments/global-settings';

@Component({
  selector: 'app-idle-session-box',
  templateUrl: './idle-session-box.component.html',
  styleUrls: ['./idle-session-box.component.scss']
})

export class IdleSessionBoxComponent {
  @ViewChild('btnYes') btnYes: ElementRef | undefined;
  timerTimeInMinutes = GlobalSettings.SessionTimer;

  constructor(
    public dialogRef: MatDialogRef<IdleSessionBoxComponent>,
    private authService: AuthService
  ) {
    dialogRef.disableClose = true;
    dialogRef.backdropClick().subscribe(() => {
       this.btnYes?.nativeElement.focus();
    });
  }

  answerYes(): void {
    this.dialogRef.close('yes');
    this.authService.getLogin();
  }
  answerNo(): void {
    this.dialogRef.close('no');
    this.endSession();
  }
  endSession(): void {
    this.authService.logoutUser();
  }
}