import { Component, Input, Output, EventEmitter } from '@angular/core';
import { map, takeWhile, tap, timer } from 'rxjs';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.scss']
})
export class CountDownComponent {

    @Input() timerMinutes!: number;

    @Output() onTimerFinished: EventEmitter<any> = new EventEmitter();

    timeRemaining$ = timer(0, 1000).pipe(
      map(n => (this.timerMinutes * 60 - n) * 1000),
      takeWhile(n => n >= 0),
      tap({
        complete: () => this.onTimerFinished.emit()
      })
    );
}
